import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Layout from '../layouts/Layout';
import ArchReviewBoard from '../components/ArchReviewBoard';
import ArchitectReviewBanner from '../components/ArchitectReviewBanner';
import htmlContents from "../../data/architectReviewBoards.json";

export const ArchitectReviewBoardTemplate = context => {
  const {
    pageContext: {
      items,
      lang
    }
  } = context;

  let htmlContent = Object.hasOwnProperty.call(htmlContents, lang) ? htmlContents[lang].html : htmlContents['en'].html;
  let html = htmlContent.replace('${REVIEW_BOARD_TABLE}', ReactDOMServer.renderToString(<ArchReviewBoard lang={lang} items={items} />));

  return (
    <Layout>
      <ArchitectReviewBanner lang={lang} />
      <div className="bg-image--gray-icons slds-p-bottom--x-large">
      	<div className="slds-container--center slds-container--medium slds-m-bottom--x-large slds-is--relative Pt(1px)">
      		<div className="box-overlaped-top cs-box-1">
            <div dangerouslySetInnerHTML={{__html: html}}></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ArchitectReviewBoardTemplate;
